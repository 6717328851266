<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        style="margin-right: 15px"
        type="primary"
        class="org back-btn"
        @click="$router.push({ name: 'virtual-reception' })"
      >
        <i class="t-transition icons icon-chevron-left" /> Назад
      </a-button>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'help-topics-create' })"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <h2>{{ title[$i18n.locale] }}</h2>
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" /> Удалить
      </a-button>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="(record) => record.id"
      class="faqs"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>
      <template slot="theme" slot-scope="item">
        <span
          class="text-center td-post-item"
          style="cursor: pointer; display: block; width: 100%; text-align: left"
          @click="
            $router.push({
              name: 'help-topics-detail',
              params: { id: item.id }
            })
          "
          >{{ item.title || "Не заполнено" }}</span
        >
      </template>
      <!-- <template slot="status" slot-scope="item">
         <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
            {{ item.is_active ? $t("Published") : $t("NotPublished") }}
         </a-tag>
      </template>

      <template slot="operation" slot-scope="item">
         <a-icon class="action-btns" type="edit" @click="$router.push({ name: 'mhh-detail', params: { id: item.id } })" />
         <a-popconfirm
            cancel-text="Нет"
            ok-text="Да"
            title="Вы действительно хотите удалить?"
            @confirm="removeItem(item)"
          >
            <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
          </a-popconfirm>
      </template> -->
    </a-table>
  </a-row>
</template>

<script>
const columns = [
  {
    key: "id",
    scopedSlots: {
      customRender: "id"
    }
  },
  {
    title: "Название",
    key: "theme",
    scopedSlots: {
      customRender: "theme"
    },
    width: "95%"
  }
  // {
  //   title: this.$t("Status"),
  //   key: "status",
  //   scopedSlots: { customRender: "status" }
  // },
  // {
  //   title: this.$t("TableAction"),
  //   key: "operation",
  //   scopedSlots: { customRender: "operation" }
  // }
]
export default {
  data: () => ({
    title: {
      oz: "Murojaat mavzulari",
      uz: "Мурожаат мавзулари",
      ru: "Справочные темы",
      en: "Help topics"
    },
    loading: false,
    list: [],
    columns,
    selectedRowKeys: [],
    selectedPosts: [],
    cardsIsEmpty: true
  }),
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    async fetchData() {
      this.loading = true
      try {
        let res = await this.$api.get(
          "/admin/leadership-online-registration/appeal_topic/list/"
        )
        console.log(res)
        this.list = res && res.data
      } catch (error) {
        console.error(error)
        this.loading = false
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              `/admin/leadership-online-registration/appeal_topic/delete/${i}/`
            )
            if (res) {
              this.$message.success("Успешно удалено")
            }
          } catch (e) {
            this.$message.success("Ошибка при удалении")
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            `/admin/leadership-online-registration/appeal_topic/delete/${id}/`
          )
          if (res) {
            this.$message.success("Успешно удалено")
          }
        } catch (e) {
          this.$message.success("Ошибка при удалении")
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    }
  }
}
</script>
